"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseSetCtrl = void 0;
const alert_1 = require("@src/app/components/alert");
const ng = window.angular;
class BaseSetCtrl extends alert_1.AlertComponentCtrl {
    constructor($scope, $timeout, $locale, $location, $element, ConfigService, SoundService, UploadDataUrl) {
        super($scope);
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.$locale = $locale;
        this.$location = $location;
        this.$element = $element;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.UploadDataUrl = UploadDataUrl;
        this.sounds = this.SoundService.getOptions();
        this.played = false;
        this.timerData = {
            sound: this.$modalScope.data.sound
        };
    }
    $onInit() {
        super.$onInit();
        this.$scope.$watch('$ctrl.played', (played) => {
            if (played && this.timerData.sound) {
                this.play();
            }
            else {
                this.SoundService.soundbox.stop_all();
            }
        });
    }
    play() {
        this.played = true;
        this.SoundService.play(this.timerData.sound.label, this.timerData.soundRepeat).finally(() => {
            this.$scope.$apply(() => {
                this.played = false;
            });
        });
    }
    upload($file) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!$file) {
                alert("Selected file is to big! Please select lower then 7Mb.");
                return Promise.reject(false);
            }
            const b64 = yield this.UploadDataUrl.base64DataUrl($file);
            yield ((_a = BaseSetCtrl.gamedb) === null || _a === void 0 ? void 0 : _a.upsert('sounds', ((oldDoc) => {
                oldDoc.sounds = (oldDoc === null || oldDoc === void 0 ? void 0 : oldDoc.sounds) || {};
                oldDoc.sounds[$file.name] = b64;
                return oldDoc;
            })));
            let selectedSound = this.sounds.find(item => item.label == $file.name);
            if (selectedSound) {
                selectedSound.value = b64;
            }
            else {
                selectedSound = {
                    label: $file.name,
                    value: b64
                };
                this.sounds.push(selectedSound);
            }
            let z = {};
            z[selectedSound.label] = selectedSound.value;
            this.SoundService.loadSound(z).then(() => {
                var _a;
                //@ts-ignore
                (_a = document.querySelector('.select__search_sound')) === null || _a === void 0 ? void 0 : _a.blur();
                this.timerData.sound = {
                    label: $file.name,
                    value: ''
                };
            });
            return Promise.resolve(undefined);
        });
    }
}
exports.BaseSetCtrl = BaseSetCtrl;
BaseSetCtrl.$inject = [
    '$scope',
    '$timeout',
    '$locale',
    '$location',
    '$element',
    'ConfigService',
    'SoundService',
    'UploadDataUrl'
];
